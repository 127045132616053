<template>
  <base-button
    type="secondary"
    data-testid="instance-switcher-button"
    @click="handleOpen"
  >
    Switch Shop
  </base-button>
  <base-modal
    :show="isOpen"
    :center-header="true"
    width="600"
    class="instance-switcher__modal"
    :show-border="false"
    @close="isOpen = false"
  >
    <template #header>
      <base-text
        type="text-h2"
        class="instance-switcher__header"
      >
        Switch Shop
      </base-text>
    </template>
    <div class="instance-switcher__body">
      <div class="instance-switcher__input--wrapper">
        <base-input
          v-model.trim="search"
          placeholder="Enter shop name or shopify url"
          class="instance-dropdown__search-input"
          icon="search"
          icon-align="left"
          label="Search shop"
          :show-label="true"
          @input="handleSearch"
        />
      </div>
      <div class="instance-switcher__instances">
        <button
          v-for="instance in instanceResults"
          :key="instance.shop_id"
          class="instance-switcher__instance"
          :class="{ 'instance-switcher__instance--active': instance.active }"
          @click="handleInstanceSelect(instance)"
        >
          <user-avatar
            size="large"
            :color="instance.color"
          >
            {{ initials(instance.name) }}
          </user-avatar>
          <div class="instance-switcher__instance--text">
            <base-text
              type="text-h4"
              class="instance-switcher__heading"
            >
              {{ instance.name }}
              <flag-icon
                class="instance-switcher__flag"
                :iso="instance.country.toLowerCase()"
                width="20px"
              />
            </base-text>
            <base-text
              type="text-body"
              color="secondary"
            >
              {{ instance.provider_domain }}
            </base-text>
          </div>
          <svg-icon
            v-if="instance.active"
            class="instance-switcher__check"
            name="check"
          />
        </button>
      </div>
    </div>
  </base-modal>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { auth0 } from '@/util/auth0';
import BaseModal from '@/components/overlays/BaseModal.vue';
import { BaseButton, BaseInput, UserAvatar, BaseText, SvgIcon, FlagIcon } from '@loophq/ui';
import { enablePageScroll, disablePageScroll } from 'scroll-lock';
import { useStore } from 'vuex';
import account from '@/util/api/account';
import { featureFlags } from '@/util/schemas/featureFlags';
import { cleanupUser } from '@/util/helpers/users';

const router = useRouter();

const isOpen = ref(false);
const search = ref('');
const instanceResults = ref([]);
const colorsArray = ['#DBE7FF', '#EDE6F3', '#D3EDDF', '#F3EDEB', '#F3F6D4'];

const store = useStore();

const shop = computed(() => {
  return store.state.userData ? store.state.userData.shop : null;
});

const instances = computed(() => {
  //return from getters and add active property
  return store.getters['instances'].map((instance, index) => {
    return {
      ...instance,
      active: instance.shop_id === shop.value.id,
      color: colorsArray[index % colorsArray.length],
    };
  });
});


watch(isOpen, (newValue) => {
  if (newValue) {
    disablePageScroll();
  } else {
    enablePageScroll();
  }
});

const escapeHandler = (e) => {
  if (!isOpen.value) {
    return;
  }

  if (e.key === 'Escape') {
    isOpen.value = false;
  }
};

const handleSearch = () => {
  instanceResults.value = instances.value.filter(shopItem => {
    return shopItem.name.toLowerCase().includes(search.value.toLowerCase()) || shopItem.provider_domain.toLowerCase().includes(search.value.toLowerCase());
  });
};

const handleInstanceSelect = async (instance) => {
  if (store.getters.hasFeature(featureFlags.AUTH0_SESSIONS_ENABLED)) {
    cleanupUser();
    router.push({ name: 'SwitchShop' });
  } else {
    // If the user has a different auth_provider_user_id than the corresponding user from the instance
    // that has been selected, we may need to do some housekeeping to enable the switch to occur seamlessly.
    if (store.state.userData?.auth_provider_user_id !== instance.auth_provider_user_id) {
      await account.enableSwitchShop(instance.shop_id);
    }

    await auth0.loginWithRedirect({
      cacheMode: 'off',
      authorizationParams: {
        prompt: 'none',
        organization: instance.auth_provider_organization_id,
      },
    });
  }
};

const initials = (value) => {
  return value.split(' ').map(word => word[0]).join('');
};

// Lifecycle hooks
onMounted(() => {
  document.documentElement.addEventListener('keydown', escapeHandler);
});

onBeforeUnmount(() => {
  document.documentElement.removeEventListener('keydown', escapeHandler);
});

const handleOpen = async () => {
  if (store.getters.hasFeature(featureFlags.AUTH0_SESSIONS_ENABLED)) {
    await handleInstanceSelect();
  } else {
    isOpen.value = true;
    instanceResults.value = instances.value;
  }
};

// Setup initial state
instanceResults.value = instances.value;

</script>

<style lang="scss" scoped>
.instance-switcher {
  &__body {
    padding: var(--spacing-16);
  }

  &__input--wrapper {
    margin-bottom: 20px;
    width: 100%;
  }

  &__header {
    font-weight: 500 !important;
  }

  &__search-input {
    margin-bottom: 0;
  }

  &__instances {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;
    flex-wrap: wrap;
  }

  &__instance {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
    padding: var(--spacing-8);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover,
    &:focus {
      background-color: var(--color-neutral-50);
    }

    &:focus {
      outline: 1px solid var(--color-neutral-900);
    }

    &--text {
      text-align: left;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    margin-bottom: var(--spacing-4);
  }

  &__instance--active {
    background-color: var(--color-neutral-100);
  }

  &__check {
    margin-left: auto;
  }
}

:deep(.base-input__wrapper) {
  width: 100% !important;
}

:deep(.base-input__field) {
  width: 100% !important;
}

:deep(.base-modal__body) {
  height: 100%;
  overflow: auto;
}

:deep(.base-input__label) {
  font-weight: 400 !important;
  color: var(--color-neutral-900);
}

</style>
